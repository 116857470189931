<template>
  <div class="desk-about" id="scroll_in2">
    <panav :color='color' :bgColor='bgColor' />
    <img src="~img/11.svg" class="logo">
    <div class="title" v-html="desc"></div>
    <div class="img-list" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <a :href="url_one">
        <img :src="image_one_url" v-if="showOne" @mouseover='showOne = false'>
        <img :src="qrcode_one_url" v-if="!showOne" @mouseleave='showOne = true'>
        <p>{{title_one}}</p>
      </a>
      <a :href="url_two">
        <img :src="image_two_url" v-if="showTwo" @mouseover='showTwo = false'>
        <img :src="qrcode_two_url" v-if="!showTwo" @mouseleave='showTwo = true'>
        <p>{{title_two}}</p>
      </a>
      <a :href="url_three">
        <img :src="image_three_url" v-if="showThree" @mouseover='showThree = false'>
        <img :src="qrcode_three_url" v-if="!showThree" @mouseleave='showThree = true'>
        <p>{{title_three}}</p>
      </a>
      <a :href="url_four">
        <img :src="image_four_url" v-if="showFour" @mouseover='showFour = false'>
        <img :src="qrcode_four_url" v-if="!showFour" @mouseleave='showFour = true'>
        <p>{{title_four}}</p>
      </a>
    </div>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>



<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      color: "#4aaeff",
      noticeColor: "#ffffcc",
      background: "#4aaeff",
      bgColor: "#ffffcc",
      desc: "",
      image_one_url: "",
      image_two_url: "",
      image_three_url: "",
      image_four_url: "",
      qrcode_one_url: "",
      qrcode_two_url: "",
      qrcode_three_url: "",
      qrcode_four_url: "",
      url_one: "",
      url_two: "",
      url_three: "",
      url_four: "",
      timer: "",
      showOne: true,
      showTwo: true,
      showThree: true,
      showFour: true,
      title_one: "",
      title_three: "",
      title_two: "",
      title_four: "",
    };
  },
  created() {
    this.getAbout();
  },
  mounted() {
    document.getElementById("scroll_in2").scrollTop = 0;
    this.autoSroll("scroll_in2");
  },
  beforeDestroy() {
    window.clearInterval(this.timer); // 清除定时器
  },
  methods: {
    mouseOver() {
      console.log("移入");
      window.clearInterval(this.timer);
    },
    mouseLeave() {
      console.log("移出");
      this.autoSroll("scroll_in2");
    },
    autoSroll(Id) {
      // flag 为true时停止滚动
      window.clearInterval(this.timer);
      let flag = false;
      // 定时器
      // let timer;
      var h = -1;
      this.timer = window.setInterval(() => {
        flag = true;
        var current = document.getElementById(Id).scrollTop; //获取当前滚动条高度
        if (current == h) {
          //滚动到底端,返回顶端
          // h = 0;
          // document.getElementById(Id).scrollTop = h + 1;
          window.clearInterval(this.timer);
          console.log("到底");
        } else {
          //以25ms/3.5px的速度滚动
          h = current;
          document.getElementById(Id).scrollTop = h + 1;
        }
      }, 50);
      //滚动区域内单击鼠标 滚动暂停 再次点击鼠标 继续滚动
      // document.getElementById(Id).onclick = () => {
      //   console.log("点击了", timer, flag);
      //   if (flag) {
      //     flag = false;
      //      clearInterval(timer);
      //   } else {
      //     roll();
      //   }
      // };
    },
    async getAbout() {
      const res = await this.axios("/api/index/about");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.desc = data.desc;
        this.image_one_url = data.image_one_url;
        this.image_two_url = data.image_two_url;
        this.image_three_url = data.image_three_url;
        this.image_four_url = data.image_four_url;
        this.qrcode_one_url = data.qrcode_one_url;
        this.qrcode_two_url = data.qrcode_two_url;
        this.qrcode_three_url = data.qrcode_three_url;
        this.qrcode_four_url = data.qrcode_four_url;
        console.log(this.qrcode_four_url);
        this.url_one = data.url_one;
        this.url_two = data.url_two;
        this.url_three = data.url_three;
        this.url_four = data.url_four;
        this.title_one = data.title_one;
        this.title_three = data.title_three;
        this.title_two = data.title_two;
        this.title_four = data.title_four;
      }
    },
  },
};
</script>


<style lang="less" scoped>
// #4aaeff
html {
  background: #ffffcc;
}
.desk-about {
  background: #ffffcc;
  padding-top: 4.72rem /* 472/100 */;
  height: 100vh;
  overflow-y: auto;
  .logo {
    width: 4rem /* 818/100 */;
    display: block;
    margin: auto;
    // margin-top: 4rem /* 400/100 */;
  }
  .title {
    text-align: center;
    margin-top: 0.85rem /* 85/100 */;
    color: #64471a;
    font-size: 0.21rem /* 21/100 */;
    font-family: PHANBO;
  }
  .img-list {
    margin-top: 1.5rem /* 50/100 */;
    padding-bottom: 1.5rem /* 150/100 */;
    margin-left: 30%;
    margin-right: 30%;
    img {
      width: 40%;
      display: block;
      margin: auto;
      margin-top: 1rem /* 100/100 */;
    }
    p {
      font-size: 0.21rem;
      color: #624b24;
      text-align: center;
      margin-top: 0.1rem;
      font-family: PHANBO;
    }
  }
}
</style>
